$(document).foundation();

$( function() { $( "#services" ).selectmenu(); } );

$(document).ready(function() {
  $("#mobile-nav-trigger").click(function() {
    $("#mobile-navigation").fadeToggle();
  });
  
//  $('#service-dropdown').on('selectmenuchange', function() {
//    window.location.href = "service.html";
//  });
  
  $("#services").selectmenu({
    change: function (event, data) {
        var serviceUrl = $("#service-dropdown").find(':selected').val();
        window.location.href = serviceUrl;
        
    }
  });
  
});

//window.onresize = function(event) {
//    if(event.currentTarget.outerWidth <= 719) {
//        console.info("showing");
//        $("#mobile-navigation").show();
//    }else{
//        console.info("hiding");
//        $("#mobile-navigation").hide();
//    }
//    
//}

